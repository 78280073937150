//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      loading: false,
      formItemLayout: {
        labelCol: { xs: { span: 24 }, sm: { span: 6 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 14 } }
      },
      tailFormItemLayout: {
        wrapperCol: { xs: { span: 24, offset: 0 }, sm: { span: 14, offset: 6 } }
      },
      // For password
      password: null,
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'change-password' });
  },
  methods: {
    validateConfirmPass(rule, value, callback) {
      if (!value) return callback(new Error(this.$t('Please input confirm password')));
      const newPass = this.form.getFieldValue('newPassword');
      if (value !== newPass) {
        return callback(new Error(this.$t('Confirm password does not match')));
      }
      callback();
    },
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          try {
            this.loading = true;
            delete values.confirmPassword;
            await this.$s.api.setting.changePassword(values);
            this.$notification.success({
              message: this.$t('Change password is succeed')
            });
          } catch (error) {
            this.$notification.error({
              message: this.$t('Error'),
              description: this.$t(error.message),
              duration: 3
            });
          } finally {
            this.loading = false;
          }
        }
      });
    },
    checkPassword({ target }) {
      this.password = target.value;
      this.password_length = this.password.length;
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      if (this.password_length > 7) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
      this.contains_special_character = format.test(this.password);

      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    }
  }
};
