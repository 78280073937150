var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    {
      attrs: { form: _vm.form },
      on: {
        submit: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.handleSubmit($event)
        }
      }
    },
    [
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { label: _vm.$t("Enter old password") } },
          "a-form-item",
          _vm.formItemLayout,
          false
        ),
        [
          _c("a-input-password", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "oldPassword",
                  {
                    rules: [
                      {
                        required: true,
                        message: _vm.$t("Please input your old password!")
                      }
                    ]
                  }
                ],
                expression:
                  "[\n        'oldPassword',\n        {\n          rules: [{ required: true, message: $t('Please input your old password!') }]\n        }\n      ]"
              }
            ],
            attrs: { placeholder: _vm.$t("Enter old password") }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { label: _vm.$t("Enter new password") } },
          "a-form-item",
          _vm.formItemLayout,
          false
        ),
        [
          _c(
            "a-popover",
            { attrs: { placement: "right" } },
            [
              _c("template", { slot: "content" }, [
                _c("div", { staticClass: "input_container" }, [
                  _c("ul", [
                    _c(
                      "li",
                      { class: { is_valid: _vm.contains_eight_characters } },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("At least 8 characters")) + " "
                        )
                      ]
                    ),
                    _c("li", { class: { is_valid: _vm.contains_number } }, [
                      _vm._v(_vm._s(_vm.$t("Include number")))
                    ]),
                    _c("li", { class: { is_valid: _vm.contains_uppercase } }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("Include upper character")) + " "
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "checkmark_container",
                      class: { show_checkmark: _vm.valid_password }
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "50%",
                            height: "50%",
                            viewBox: "0 0 140 100"
                          }
                        },
                        [
                          _c("path", {
                            staticClass: "checkmark",
                            class: { checked: _vm.valid_password },
                            attrs: { d: "M10,50 l25,40 l95,-70" }
                          })
                        ]
                      )
                    ]
                  )
                ])
              ]),
              _c("a-input-password", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "newPassword",
                      {
                        rules: [
                          {
                            required: true,
                            message: _vm.$t(
                              "Password length at least 8 characters"
                            )
                          },
                          {
                            min: 8,
                            message: _vm.$t(
                              "Password length at least 8 characters"
                            )
                          }
                        ],
                        validateTrigger: ["change", "blur"]
                      }
                    ],
                    expression:
                      "[\n          'newPassword',\n          {\n            rules: [\n              { required: true, message: $t('Password length at least 8 characters') },\n              { min: 8, message: $t('Password length at least 8 characters') }\n            ],\n            validateTrigger: ['change', 'blur']\n          }\n        ]"
                  }
                ],
                attrs: { placeholder: _vm.$t("Enter new password") },
                on: { change: _vm.checkPassword }
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { required: "", label: _vm.$t("Confirm new password") } },
          "a-form-item",
          _vm.formItemLayout,
          false
        ),
        [
          _c("a-input-password", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "confirmPassword",
                  {
                    rules: [{ validator: _vm.validateConfirmPass }]
                  }
                ],
                expression:
                  "[\n        'confirmPassword',\n        {\n          rules: [{ validator: validateConfirmPass }]\n        }\n      ]"
              }
            ],
            attrs: { placeholder: _vm.$t("Confirm new password") }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b({}, "a-form-item", _vm.tailFormItemLayout, false),
        [
          _c(
            "a-button",
            {
              staticClass: "update-form-button",
              staticStyle: { width: "100%" },
              attrs: {
                loading: _vm.loading,
                type: "primary",
                "html-type": "submit"
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }